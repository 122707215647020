import React from "react";
import { NavLink } from "react-router-dom";
import "./hamburger-menu.css";

export default class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.state = { visible: false };
  }

  handleClick() {
    console.log("clicked");
    this.setState({ visible: !this.state.visible });
  }

  render() {
    return (
      <div>
        <div id="small-menu" onClick={this.handleClick}>
          <div>
            <div className="each-bar" />
            <div className="each-bar" />
            <div className="each-bar" />
          </div>
        </div>
        <ul
          id="hamburger-menu"
          className={
            this.state.visible
              ? `slide-in ${this.props.currentHeight}-hmenu`
              : `slide-out ${this.props.currentHeight}-hmenu`
          }
        >
          <li>
            <NavLink to="/" href="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/The-Firm" href="/home">
              The Firm
            </NavLink>
            <ul className="sub-menu">
              <li>
                <NavLink to="/The-Firm" href="/The-Firm">
                  Why Us?
                </NavLink>
              </li>
              <li>
                <NavLink to="/Approach" href="/Approach">
                  Our Approach
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/Attorneys" href="/Attorneys">
              Attorneys
            </NavLink>
          </li>
          <li>
            <NavLink to="/Practice-Areas" href="/Practice-Areas">
              Practice
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}
