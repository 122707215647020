import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/home";
import Firm from "./pages/firm";
import Attorneys from "./pages/attorneys";
import PracticeAreas from "./pages/practice";
import Mediation from "./pages/mediation";
import Contact from "./pages/contact";
import Approach from "./pages/approach";
import TermsOfUse from "./pages/terms-of-use";
import PrivacyPolicy from "./pages/privacy-policy";
import PhilipCook from "./pages/people/philip-cook";
import CorporateGovernanceSecuritiesExperience from "./pages/additional-pages/corporate-governance-securities-experience";
import InsuranceExperience from "./pages/additional-pages/insurance-experience";
import OtherExperienceHighlights from "./pages/additional-pages/other-experience";
import Footer from "./footer/footer";

export default class App extends React.Component {
  render() {
    const mainStyles = {
      position: "relative",
      minHeight: "100vh"
    };

    const contentStyles = {
      paddingBottom: "2.5rem",
      paddingTop: "76px"
    };
    return (
      <div style={mainStyles}>
        <div style={contentStyles} onScroll={this.handleScroll}>
          <Switch>
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-of-use" component={TermsOfUse} />
            <Route
              path="/bio/other_experience_highlights/philip_cook"
              component={OtherExperienceHighlights}
            />
            <Route
              path="/bio/securities_corporate_governance/philip_cook"
              component={CorporateGovernanceSecuritiesExperience}
            />
            <Route
              path="/bio/insurance_experience_highlights/philip_cook"
              component={InsuranceExperience}
            />
            <Route path="/bio/philip_cook" component={PhilipCook} />
            <Route path="/Approach" component={Approach} />
            <Route path="/Practice-Areas" component={PracticeAreas} />
            <Route path="/Attorneys" component={Attorneys} />
            <Route path="/The-Firm" component={Firm} />
            <Route path="/" component={Home} />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}
