import React from "react";
import "./navigation.css";
import { NavLink } from "react-router-dom";
import cooklawfirmlogo from "../assets/images/cooklawfirmlogo.png";
import cookmediationlogo from "../assets/images/cookmediationlogo.png";
import HamburgerMenu from "./hamburger-menu";

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isScrolled: false,
      currentScrollClass: "default-scroll"
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.removeBanner = this.removeBanner.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  removeBanner() {
    localStorage.setItem("CLFBanner", false);
    this.setState({ showBanner: false });
  }

  handleScroll(event) {
    let sHeight = document.body.scrollTop || document.documentElement.scrollTop;
    if (sHeight >= 48 && sHeight < 56) {
      this.setState({
        isScrolled: true,
        currentScrollClass: "isScrolledDown-xl"
      });
    } else if (sHeight >= 56 && sHeight < 64) {
      this.setState({
        isScrolled: true,
        currentScrollClass: "isScrolledDown-lg"
      });
    } else if (sHeight >= 64 && sHeight < 70) {
      this.setState({
        isScrolled: true,
        currentScrollClass: "isScrolledDown-md"
      });
    } else if (sHeight >= 70 && sHeight < 78) {
      this.setState({
        isScrolled: true,
        currentScrollClass: "isScrolledDown-sm"
      });
    } else if (sHeight >= 78 && sHeight < 86) {
      this.setState({
        isScrolled: true,
        currentScrollClass: "isScrolledDown"
      });
    } else if (sHeight < 48) {
      this.setState({
        isScrolled: false,
        currentScrollClass: "default-scroll"
      });
    }
  }

  render() {
    const isMediationSite = window.location.origin.includes("mediation");
    const logo = isMediationSite ? cookmediationlogo : cooklawfirmlogo;

    return (
      <header id="header" className="header_2">
        <div className="container">
          <div
            className={`row-fluid ${
              this.state.isScrolled ? this.state.currentScrollClass : ""
            }`}
          >
            <div className="span12">
              <div id="logo" className="">
                <NavLink to="/" href="/">
                  <img
                    src={logo}
                    alt="Cook Law Firm Logo"
                    className="clf-logo"
                  />
                </NavLink>{" "}
                <span className="logo_desc" />
              </div>
              <div id="navigation" className="nav_top pull-right">
                <nav>
                  <ul id="menu-menu" className="menu">
                    <li>
                      <NavLink
                        activeStyle={
                          window.location.pathname === "/"
                            ? { color: "#FD150B" }
                            : {}
                        }
                        to="/"
                        href="/"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        style={
                          window.location.pathname === "/The-Firm" ||
                          window.location.pathname === "/Approach"
                            ? { color: "#FD150B" }
                            : {}
                        }
                        to="/The-Firm"
                        href="/The-Firm"
                      >
                        The Firm
                      </NavLink>
                      <ul className="sub-menu">
                        <li>
                          <NavLink to="/The-Firm" href="/The-Firm">
                            Why Us?
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/Approach" href="/Approach">
                            Our Approach
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <NavLink
                        activeStyle={{ color: "#FD150B" }}
                        to="/Attorneys"
                        href="/Attorneys"
                      >
                        Attorneys
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        style={
                          window.location.pathname === "/Practice-Areas"
                            ? { color: "#FD150B" }
                            : {}
                        }
                        to="/Practice-Areas"
                        href="/Practice-Areas"
                      >
                        Practice
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        activeStyle={{ color: "#FD150B" }}
                        to="/Mediation"
                        href="/Mediation"
                      >
                        Mediation
                      </NavLink>
                    </li> */}
                    {/* <li>
                      <NavLink
                        activeStyle={{ color: "#FD150B" }}
                        to="/Contact"
                        href="/Contact"
                      >
                        Contact
                      </NavLink>
                    </li> */}
                  </ul>
                  <HamburgerMenu
                    currentHeight={this.state.currentScrollClass}
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
        <span className="shadow" />
      </header>
    );
  }
}
