import React from "react";
import Navigation from "../navigation/navigation";
import TinySlider from "./tinySlider/tinySlider";
import imageOne from "./attorneyImages/pcook.jpg";
import imageTwo from "./attorneyImages/bbhirsch.jpg";
import imageThree from "./attorneyImages/bjwright.jpg";
import "./attorneys.css";
import { Link } from "react-router-dom";

export default class Attorneys extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [
        {
          name: `Philip E. Cook`,
          title: `Managing Attorney`,
          contact: `Direct: 213-988-6100`,
          email: ``,
          img: imageOne,
          content: [
            `<div>Phil has tried cases before judges, juries, arbitrators and arbitration panels and argued appeals in both federal and state courts. Phil’s practice currently focuses on insurance recovery for policyholders and business-to-business disputes in a variety of contexts. His success in the courtroom covers the gamit of commercial litigation, including individual and class action cases involving contract disputes; corporate governance and securities and financial products claims; Business and Professions Code section 17200 claims and business torts; real estate, land use and environmental issues; product liability and toxic torts; antitrust and trade regulation; patent, trademark and copyright infringement; trade secrets; governmental claims and liabilities; freedom of speech and religious protection under the First Amendment; health care, construction defect, and wrongful termination, employment discrimination and executive compensation.</div>`,
            `Read More ...`
          ],

          personalLink: "/bio/philip_cook",
          other: [
            {
              type: `Education`,
              content: `University of California, Los Angeles (J.D. 1990; Editor, Law Review); Pacific Christian College (B.A. Psychology, 1979)`
            },
            {
              type: `Admitted:`,
              content: `California, all U.S. District Courts in California, U.S. Court of Appeals for Ninth Circuit, and pro hac vice in courts in Indiana, Oregon, North Carolina, Texas, Washington and Hawaii`
            },
            {
              type: `Clerkship:`,
              content: `<div className="content-type">Law Clerk to Judge Consuelo B. Marshall, United States District Court, Central District of California (1991-92)</div>`
            }
          ]
        }
      ]
    };
  }

  componentDidMount() {
    //scroll to top for react-router-dom
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="attorneys">
        <Navigation />
        <div className="container push-top">
          <div className="row">
            <aside className="col-md-3 side-bar-hidden attorneys-sidebar">
              <TinySlider />
            </aside>
            <div className="col-md-9 medium-screen-overwrite">
              <div className="row push-top-lg">
                <div>
                  {this.state.content.map((eachPerson, z) => (
                    <React.Fragment key={z}>
                      <div className="row push-bottom-lg">
                        <div className="col-md-3">
                          <div className="attorney-img-text-wrapper-mobile">
                            <img
                              className="attorney-imgs"
                              src={eachPerson.img}
                              alt={eachPerson.name}
                            />
                            <div className="mobile-view-attorneys">
                              <p className="bold">
                                {eachPerson.personalLink ? (
                                  <div to={eachPerson.personalLink}>
                                    {eachPerson.name}
                                  </div>
                                ) : (
                                  eachPerson.name
                                )}
                              </p>
                              <p className="bold">{eachPerson.title}</p>
                              <p className="contact">{eachPerson.contact}</p>
                              <a
                                className="mail-link"
                                href={`mailto:${eachPerson.email}?Subject=Cook%20Law%20Firm`}
                                target="_top"
                              >
                                {eachPerson.email}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="push-bottom">
                            {eachPerson.content.map((eachParagraph, i) => (
                              <React.Fragment key={i}>
                                {eachParagraph === `Read More ...` ? (
                                  <Link
                                    to={eachPerson.personalLink}
                                    className="read-more-link"
                                  >
                                    {eachParagraph}
                                  </Link>
                                ) : (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: eachParagraph
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            ))}
                          </div>

                          {eachPerson.other.map((eachOther, k) => (
                            <div key={k}>
                              <span className="bold-title">
                                <div
                                  className="content-type"
                                  dangerouslySetInnerHTML={{
                                    __html: eachOther.type
                                  }}
                                />
                              </span>{" "}
                              <div
                                className="attorneys-text"
                                dangerouslySetInnerHTML={{
                                  __html: eachOther.content
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
